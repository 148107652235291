/* Main TeSEO Application Script */

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


var TeSEO = TeSEO || {};

(function($) {

  "use strict";

  TeSEO.main = {

    headerOffset : 0,
    cookiesHeight: 0,

    responsiveClasses: function(){

      mediaCheck({
        media: '(max-width: 479px)',
        entry: function() {
          $body.addClass('device-xxs');
        },
        exit: function() {
          $body.removeClass('device-xxs');
        }
      });

      mediaCheck({
        media: '(min-width: 480px) and (max-width: 767px)',
        entry: function() {
          $body.addClass('device-xs');
        },
        exit: function() {
          $body.removeClass('device-xs');
        }
      });

      mediaCheck({
        media: '(min-width: 768px) and (max-width: 991px)',
        entry: function() {
          $body.addClass('device-sm');
        },
        exit: function() {
          $body.removeClass('device-sm');
        }
      });

      mediaCheck({
        media: '(min-width: 992px) and (max-width: 1199px)',
        entry: function() {
          $body.addClass('device-md');
        },
        exit: function() {
          $body.removeClass('device-md');
        }
      });

      mediaCheck({
        media: '(min-width: 1200px)',
        entry: function() {
          $body.addClass('device-lg');
        },
        exit: function() {
          $body.removeClass('device-lg');
        }
      });

      if( TeSEO.isMobile.any() ){
        $body.addClass('device-touch');
      }

    },

    imagePreload: function(selector, parameters){
      var params = {
        delay: 250,
        transition: 400,
        easing: 'linear'
      };
      $.extend(params, parameters);

      $(selector).each(function() {
        var image = $(this);
        image.css({visibility:'hidden', opacity: 0, display:'block'});
        image.wrap('<span class="preloader" />');
        image.one("load", function(evt) {
          $(this).delay(params.delay).css({visibility:'visible'}).animate({opacity: 1}, params.transition, params.easing, function() {
            $(this).unwrap('<span class="preloader" />');
          });
        }).each(function() {
          if(this.complete) { $(this).trigger("load"); }
        });
      });
    },

    /**
      * Setup slimscroll
      * @param {string} [elms] jquery selection (optional)
      */
    slimScroll: function(elms) {
        elms = elms || '.scroller';
        $(elms).each(function () {
            var height;
            if ($(this).data("height")) {
                height = $(this).data("height");
            } else {
                height = 'auto';
            }
            $(this).slimScroll({
                size            : '5px',
                distance        : 0,
                color           : '#999',
                height          :  height,
                allowPageScroll : true,
                alwaysVisible   : false,
                disableFadeOut  : true,
                railVisible     : false,
                railOpacity     : 0
            });
        });
    },

    goToTop: function(){
      $goToTopEl.on('click', function() {
        $('body,html').stop(true).animate({scrollTop:0}, 600 );
        return false;
      });
      TeSEO.main.goToTopScroll();
    },

    goToTopScroll: function(){
      if( $body.hasClass('device-lg') || $body.hasClass('device-md') || $body.hasClass('device-sm') ) {
        if($window.scrollTop() > 500) {
          $goToTopEl.fadeIn();
        } else {
          $goToTopEl.fadeOut();
        }
      }
    },

    linkScroll: function(){
      $("a[data-scrollto]").click(function(){
        var element = $(this),
          divScrollToAnchor = element.attr('data-scrollto'),
          divScrollSpeed = element.attr('data-speed'),
          divScrollOffset = element.attr('data-offset'),
          divScrollEasing = element.attr('data-easing'),
          divScrollFocus = element.attr('data-focus');

          if( !divScrollSpeed ) { divScrollSpeed = 750; }
          if( !divScrollOffset ) { divScrollOffset =  90; }
          if( !divScrollEasing ) { divScrollEasing = 'easeOutQuad'; }

        $('html,body').stop(true).animate({
          'scrollTop': $( divScrollToAnchor ).offset().top - Number(divScrollOffset)
        }, Number(divScrollSpeed), divScrollEasing, function(){
          window.location.hash = divScrollToAnchor;
          if( divScrollFocus !== undefined &&  $(divScrollFocus).length) {
            $(divScrollFocus).focus();
          }
        });

        return false;
      });

      $(".comment-metadata > a").on('click',function(e){
        var href = $(this).attr('href');


        $('html,body').stop(true).animate({

          'scrollTop': $( '#' + $(this).closest('.comment-body').attr('id') ).offset().top - 90}, 600, 'easeOutQuad', function () {
              if ( /(#.*)/.test(href) ){
                var hash = href.match(/(#.*)/)[0];
                window.location.hash = hash;
              }
        });
        return false;
      });

    },

    resizeVideos: function(){
      if ( $().fitVids ) {
        $("#content").fitVids();
      }
    },

    imageFade: function(){
      $('.image_fade').hover( function(){
        $(this).filter(':not(:animated)').animate({opacity: 0.8}, 400);
      }, function() {
        $(this).animate({opacity: 1}, 400);
      });
    },

    pageTransition: function(){
      if($body.hasClass('with-transitions')){

        var transitionIn = $body.attr('data-transition-in'),
            transitionOut = $body.attr('data-transition-out'),
            durationIn = $body.attr('data-duration-in'),
            durationOut = $body.attr('data-duration-out');

        if( !transitionIn ) { transitionIn = 'fade-in'; }
        if( !transitionOut ) { transitionOut = 'fade-out'; }
        if( !durationIn ) { durationIn = 700; }
        if( !durationOut ) { durationOut = 300; }


        $wrapper.animsition({
          inClass: transitionIn,
          outClass: transitionOut,
          inDuration: Number(durationIn),
          outDuration: Number(durationOut),
          linkElement: 'a:not([target="_blank"]):not([href*="#"]):not([href*="#comment"]):not([href^="tel\\:"]):not([href^="mailto\\:"]):not([data-lightbox]):not(.bx-pager-link,.bx-prev,.bx-next,.comment-reply-link, #cancel-comment-reply-link)',
          loading: true,
          loadingParentElement: 'body',
          loadingClass: 'animsition-spinner',
          loadingInner: '<div class="spin1"></div><div class="spin2"></div><div class="spin3"></div><div class="spin4"></div>',
          timeout: false,
          timeoutCountdown: 5000,
          onLoadEvent: true,
          browser: [ 'animation-duration', '-webkit-animation-duration', '-o-animation-duration'],
          overlay : false,
          overlayClass : 'animsition-overlay',
          overlayParentElement : 'body'
        });

      }
    },

    stickFooterOnSmall: function(){
      var windowH = $window.height(),
        wrapperH = $wrapper.height();

      if( !$body.hasClass('sticky-footer') && $footer.length > 0 && $wrapper.has('#footer') ) {
        if( windowH > wrapperH ) {
          $footer.css({ 'margin-top': ( windowH - wrapperH) });
        }
      }
    },

    stickyFooter: function(){
      if( $body.hasClass('sticky-footer') && $footer.length > 0 && ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) ) {
        var stickyFooter = $footer.outerHeight();
        $content.css({ 'margin-bottom': stickyFooter });
      } else {
        $content.css({ 'margin-bottom': 0 });
      }
    },

    prepareStickyMenu: function(){
      if( $header.length > 0 ) {
          TeSEO.main.headerOffset= $header.outerHeight() - $headerWrap.outerHeight() + $header.offset().top;
          if($disclaimer.length > 0 && $disclaimer.hasClass('cookies-to-top') &&  $disclaimer.is(':visible')){
            TeSEO.main.cookiesHeight = $disclaimer.outerHeight();
          }
      }
    },

    stickyMenu: function(){
      var h = ( $body.hasClass('disclaimer-top') ) ? TeSEO.main.headerOffset : TeSEO.main.headerOffset - TeSEO.main.cookiesHeight;
      if( $body.hasClass('device-xxs') || $body.hasClass('device-xs') || $body.hasClass('device-sm') ) {
        h = ( $body.hasClass('disclaimer-top') ) ? 0 : - TeSEO.main.cookiesHeight;
      }
      if ($window.scrollTop() > h ) {
        $body.addClass('sticky-header');
      } else {
        $body.removeClass('sticky-header');
      }
    },

    sidePanel: function(){
      $(".side-panel-trigger").click(function(ev){
        ev.preventDefault();
        $body.toggleClass("side-panel-open");
        if( $body.hasClass('device-touch') ) {
          $body.toggleClass("ohidden");
        }
        return false;
      });

      $(document).on('click', function(ev) {
        //console.log('sidePanel', ev.target);
        if (!$(ev.target).closest('#side-panel').length) { $body.toggleClass('side-panel-open', false); }
      });

    },

    parallax: function(){
      if( !TeSEO.isMobile.any() && $body.hasClass('with-parallax') ){
        $.stellar({
          horizontalScrolling: false,
          verticalOffset: 150/*,
          responsive: true*/
        });
      } else {
        $parallax.addClass('mobile-parallax');
      }
    },

    animations: function(){
      if($body.hasClass('with-animations')){
        var $dataAnimateEl = $('[data-animate]');
        if( $dataAnimateEl.length > 0 ){
          if( !TeSEO.isMobile.any() && ($body.hasClass('device-lg') || $body.hasClass('device-md') || $body.hasClass('device-sm')) ){
            $dataAnimateEl.each(function(){
              var element = $(this),
                animationDelay = element.attr('data-delay'),
                animationDelayTime = 0;

              if( animationDelay ) { animationDelayTime = Number( animationDelay ); } else { animationDelayTime = 0; }

              if( !element.hasClass('animated') ) {
                element.addClass('not-animated');
                var elementAnimation = element.attr('data-animate');

                var waypoint = new Waypoint({
                  element: element[0],
                  handler: function(direction) {
                    setTimeout(function() {
                      element.removeClass('not-animated').addClass( elementAnimation + ' animated');
                    }, animationDelayTime);
                  },
                  offset: '95%'
                });

              }
            });
          }
        }
      }
    },

    /**
    * Setup uniform checkboxes & radio buttons
    */
    uniform: function () {
      if (!jQuery().uniform) { return; }

      var $elms = $("input[type=checkbox], input[type=radio]");
      if($elms.length > 0) {
        $($elms).each(function () {
          if ($(this).parents(".checker,.radio").length === 0){
            $(this).uniform();
          }

        });
      }

      $elms = $("select");
      if($elms.length > 0) {
        $($elms).each(function () {
          if ($(this).parents(".selector").length === 0){
            $(this).uniform({selectAutoWidth: false});
          }

        });
      }

    },

    lightbox: function(){
      var $lightboxImageEl = $('[data-lightbox="image"]'),
        $lightboxIframeEl = $('[data-lightbox="iframe"]'),
        $lightboxInlineEl = $('[data-lightbox="inline"]');

      if( $lightboxImageEl.length > 0 ) {
        $lightboxImageEl.magnificPopup({
          type: 'image',
          closeOnContentClick: true,
          closeBtnInside: false,
          fixedContentPos: true,
          mainClass: 'mfp-no-margins mfp-fade', // class to remove default margin from left and right side
          image: {
            verticalFit: true
          }
        });
      }

      if( $lightboxIframeEl.length > 0 ) {
        $lightboxIframeEl.magnificPopup({
          disableOn: 600,
          type: 'iframe',
          removalDelay: 160,
          preloader: false,
          fixedContentPos: false
        });
      }

      if( $lightboxInlineEl.length > 0 ) {
        $lightboxInlineEl.magnificPopup({
          type: 'inline',
          mainClass: 'mfp-no-margins mfp-fade',
          closeBtnInside: false,
          fixedContentPos: true
        });
      }

    },

    dataResponsiveClasses: function(){
      var $dataClassXxs = $('[data-class-xxs]'),
          $dataClassXs = $('[data-class-xs]'),
          $dataClassSm = $('[data-class-sm]'),
          $dataClassMd = $('[data-class-md]'),
          $dataClassLg = $('[data-class-lg]');

      if( $dataClassXxs.length > 0 ) {
        $dataClassXxs.each( function(){
          var element = $(this),
            elementClass = element.attr('data-class-xxs'),
            elementClassDelete = element.attr('data-class-xs') + ' ' + element.attr('data-class-sm') + ' ' + element.attr('data-class-md') + ' ' + element.attr('data-class-lg');

          if( $body.hasClass('device-xxs') ) {
            element.removeClass( elementClassDelete );
            element.addClass( elementClass );
          }
        });
      }

      if( $dataClassXs.length > 0 ) {
        $dataClassXs.each( function(){
          var element = $(this),
            elementClass = element.attr('data-class-xs'),
            elementClassDelete = element.attr('data-class-xxs') + ' ' + element.attr('data-class-sm') + ' ' + element.attr('data-class-md') + ' ' + element.attr('data-class-lg');

          if( $body.hasClass('device-xs') ) {
            element.removeClass( elementClassDelete );
            element.addClass( elementClass );
          }
        });
      }

      if( $dataClassSm.length > 0 ) {
        $dataClassSm.each( function(){
          var element = $(this),
            elementClass = element.attr('data-class-sm'),
            elementClassDelete = element.attr('data-class-xxs') + ' ' + element.attr('data-class-xs') + ' ' + element.attr('data-class-md') + ' ' + element.attr('data-class-lg');

          if( $body.hasClass('device-sm') ) {
            element.removeClass( elementClassDelete );
            element.addClass( elementClass );
          }
        });
      }

      if( $dataClassMd.length > 0 ) {
        $dataClassMd.each( function(){
          var element = $(this),
            elementClass = element.attr('data-class-md'),
            elementClassDelete = element.attr('data-class-xxs') + ' ' + element.attr('data-class-xs') + ' ' + element.attr('data-class-sm') + ' ' + element.attr('data-class-lg');

          if( $body.hasClass('device-md') ) {
            element.removeClass( elementClassDelete );
            element.addClass( elementClass );
          }
        });
      }

      if( $dataClassLg.length > 0 ) {
        $dataClassLg.each( function(){
          var element = $(this),
            elementClass = element.attr('data-class-lg'),
            elementClassDelete = element.attr('data-class-xxs') + ' ' + element.attr('data-class-xs') + ' ' + element.attr('data-class-sm') + ' ' + element.attr('data-class-md');

          if( $body.hasClass('device-lg') ) {
            element.removeClass( elementClassDelete );
            element.addClass( elementClass );
          }
        });
      }
    },

    globalEvents: function(){


      if($body.hasClass('no-smooth-scroll')){
        SmoothScroll.destroy();
      }

      /*if($goToTopEl.length > 0 && $body.hasClass('device-touch')){
          $goToTopEl.hide();
      }*/

      TeSEO.main.prepareStickyMenu();

      $window.on('scroll', function(){
        TeSEO.main.goToTopScroll();
        TeSEO.main.stickyMenu();
        TeSEO.main.onScroll();
      });

      $window.on( 'resize', debounce( function(){ UTIL.fire('common', 'resize'); }, 100 ) );



    },

    onScroll: function(){
      TeSEO.slider.scrollRequest();
    }


  };


  TeSEO.slider = {

    refreshing: false,

    init: function() {
      TeSEO.slider.mainSliders();
      TeSEO.slider.tinySliders();
      TeSEO.slider.sliderParallax();
      TeSEO.slider.clientsTicker();
    },


    mainSliders: function(){

      $('.slider').each(function(){
        var transition = $(this).attr('data-transition');
        var isautos = $(this).attr('data-auto');
        var isauto = false;
        if (isautos=='yes') isauto = true;
        if (!transition) { transition = 'fade'; }

        $(this).bxSlider({
          mode: transition,
          hideControlOnEnd: true,
          touchEnabled: true,
          infiniteLoop: true,
          pager: true,
          adaptiveHeight: true,
          responsive: true,
          wrapperClass: 'slider-wrapper',
          easing: 'ease-out',
          prevText: '<span class="icon icon-chevron-left" aria-hidden="true"></span>',
          nextText: '<span class="icon icon-chevron-right" aria-hidden="true"></span>',
          auto: isauto,
          buildPager: function(index){
            return '<span class="screen-reader-text">'+ index + '</span>';
          },
          onSliderLoad: function(index){
            $('[data-caption-animate]').each(function(){
              var $animElm = $(this);
              var animDelay = $(this).attr('data-caption-delay');
              var animation = $animElm.attr('data-caption-animate');
              var animDelayTime = 0;
              if( animDelay ) { animDelayTime = Number( animDelay ); } else { animDelayTime = 0; }
              if( !$animElm.hasClass('animated') ) {
                $animElm.addClass('not-animated');
                setTimeout(function() {
                  $animElm.removeClass('not-animated').addClass( animation + ' animated');
                }, animDelayTime);
              }
            });
          },
          onSlideBefore: function($slide, oldIndex, newIndex){
            console.log('before');
            $slide.find('[data-caption-animate]').each(function(){
              var $animElm = $(this);
              var animation = $animElm.attr('data-caption-animate');
              $animElm.removeClass('animated').removeClass(animation).addClass('not-animated');
            });
          },
          onSlideAfter: function($slide, oldIndex, newIndex){
            console.log('after');
            $slide.find('[data-caption-animate]').each(function(){
              var $animElm = $(this);
              var animDelay = $(this).attr('data-caption-delay');
              var animDelayTime = 0;
              var animation = $animElm.attr('data-caption-animate');
              if( animDelay ) { animDelayTime = Number( animDelay ); } else { animDelayTime = 0; }
              if( !$animElm.hasClass('animated') ) {
                $animElm.addClass('not-animated');
                setTimeout(function() {
                  $animElm.removeClass('not-animated').addClass( animation + ' animated');
                }, animDelayTime);
              }
            });
          }
        });
      });
    },

    tinySliders: function(){
      $('.tiny-slider').bxSlider({
          mode: 'horizontal',
          hideControlOnEnd: true,
          touchEnabled: true,
          infiniteLoop: false,
          pager: false,
          adaptiveHeight: true,
          responsive: true,
          easing: 'ease-in-out',
          wrapperClass: 'tiny-slider-wrapper',
          prevText: '<span class="icon icon-chevron-left" aria-hidden="true"></span>',
          nextText: '<span class="icon icon-chevron-right" aria-hidden="true"></span>'
      });
    },

    sliderParallax: function(){
      var wst = $window.scrollTop(),
          wh = $window.height();

      $('.slider-parallax').each(function(){
        var $parallaxElm = $(this).find('.slider-parallax-outer');

        if( $parallaxElm.length > 0 ) {
          var $parallaxCaption = $parallaxElm.find('.slider-caption');
          var $sliderControls = $parallaxElm.find('.bx-controls');
          var isSlider = $sliderControls.length > 0;
          var posOuter, posInner;
          if( ( $body.hasClass('device-lg') || $body.hasClass('device-md') ) && !TeSEO.isMobile.any() ) {
            var eot = Math.round($parallaxElm.parent().offset().top),
                eh = $parallaxElm.outerHeight(),
                dift = wst-eot,
                difb = dift + wh - eh;

              if(eh > 0){
                var rz = 1 / eh;
                if (dift > 0 && dift < (eh * 2)) {
                  //console.log('wst: ' + wst, 'eot: ' + eot, 'eh: ' + eh, 'wh: ' + wh, 'dift: ' + dift, rz * dift);
                  posOuter = (dift * 0.66 ).toFixed(2);
                  posInner = (dift * 0.14 ).toFixed(2);
                  $parallaxElm.stop(true,true).transition({ y: posOuter },0);
                  $parallaxCaption.css({'opacity': 1 - (rz * dift) }).stop(true,true).transition({ y: -posInner },0);
                  if (isSlider) { $sliderControls.css({'opacity': 1 - (rz * dift) }); }
                }else if(difb < 0 && difb > (-eh * 2)){
                  //console.log('wst: ' + wst, 'eot: ' + eot, 'eh: ' + eh, 'wh: ' + wh, 'difb: ' + difb, -1 * rz * difb );
                  posOuter = (difb * 0.66 ).toFixed(2);
                  posInner = (difb * 0.14 ).toFixed(2);
                  $parallaxElm.stop(true,true).transition({ y: posOuter },0);
                  $parallaxCaption.css({'opacity': 1 - ( -1 * rz * difb) }).stop(true,true).transition({ y: -posInner },0);
                  if (isSlider) { $sliderControls.css({'opacity': 1 - ( -1 * rz * difb) }); }
                } else {
                  $parallaxElm.transition({ y: 0 },0);
                  $parallaxCaption.css({'opacity': 1}).transition({ y: 0 },0);
                  if (isSlider) { $sliderControls.css({'opacity': 1}); }
                }
              }else {
                  $parallaxElm.transition({ y: 0 },0);
                  $parallaxCaption.css({'opacity': 1}).transition({ y: 0 },0);
                  if (isSlider) { $sliderControls.css({'opacity': 1}); }

              }
          } else {
            $parallaxElm.transition({ y: 0 },0);
            $parallaxCaption.css({'opacity': 1});
            if (isSlider) { $sliderControls.css({'opacity': 1}); }
          }
        }

      });
    },

    scrollRequest: function(){
      if(!TeSEO.slider.refreshing){
        requestAnimationFrame(TeSEO.slider.scrollUpdate);
      }
      TeSEO.slider.refreshing = true;
    },

    scrollUpdate: function(){
      TeSEO.slider.sliderParallax();
      TeSEO.slider.refreshing = false;
    },

    clientsTicker: function(){
      if($('.clients-ticker').length > 0){
        $('.clients-ticker').bxSlider({
          slideWidth: 'auto',
          randomStart: true,
          slideMargin: 110,
          ticker:true,
          speed: 30000,
          useCSS: false,
          tickerHover: true
        });
      }
    }

  };

  TeSEO.validation = {

    labels: {
      captchaTip: "This is to avoid spam",
      commentMessages: {
          'author': 'Please fill the author field',
          'email': 'Please enter a valid email address',
          'url': 'Please enter a valid url',
          'comment': 'Please fill the comment field'
        },
        contactMessages: {
          'your-name': 'This field is required',
          'your-email': 'Please enter a valid email address',
          'your-subject': 'Please select an option',
          'your-message': 'This field is required',
          'accept-this': 'Please accept legal terms to continue'
      }
    },

    setupLabels: function(labels){
      TeSEO.validation.labels = labels;
    },

    initComments: function(){

      var commentsform=$('#commentform');

      if(commentsform.length){

        commentsform.find('.form-input').on('focus',function(e){
          $('#lbl-'+ $(this).attr('id')).addClass('has-focus');
        });
        commentsform.find('.form-input').on('blur',function(e){
          $('#lbl-'+ $(this).attr('id')).removeClass('has-focus');
        });

        commentsform.validate({

          errorClass: "invalid",
          onfocusout: function(element) { $(element).valid(); },

          rules: {
            author: {
              required: true
            },
            email: {
              required: true,
              email: true
            },
            comment: {
              required: true
            }
          },

          messages: TeSEO.validation.labels.commentMessages,

          errorElement: "span",
          errorPlacement: function(error, element) {
            element.after(error);
          },

          highlight: function(element, errorClass, validClass) {
            $(element).closest('p.form-field').removeClass('field-'+validClass).addClass('field-'+errorClass);
          },
          unhighlight: function(element, errorClass, validClass) {
            $(element).closest('p.form-field').removeClass('field-'+errorClass).addClass('field-'+validClass);
          }

        });
      }

    },

    initContact: function(){

      var contactform=$('#contact-form form');

      if(contactform.length){

        contactform.find('.form-input').on('focus',function(e){
          $('#lbl-'+ $(this).attr('id')).addClass('has-focus');
        });
        contactform.find('.form-input').on('blur',function(e){
          $('#lbl-'+ $(this).attr('id')).removeClass('has-focus');
        });

        contactform.validate({

          errorClass: "invalid",
          onfocusout: function(element) { $(element).valid(); },

          rules: {
            'your-name': {
              required: true
            },
            'your-email': {
              required: true,
              email: true
            },
            'your-subject': {
              required: true,
            },
            'your-message': {
              required: true
            },
            'accept-this': {
              required: true
            }
          },

          messages:  TeSEO.validation.labels.contactMessages,

          errorElement: "span",
          errorPlacement: function(error, element) {
            if (element.attr("name") === "your-subject" ) {
              error.insertAfter(".wpcf7-radio");
            } else if(element.attr("name") === 'accept-this'){
              error.insertAfter(".form-terms-wrapper");
            } else {
              error.insertAfter(element);
            }
          },

          highlight: function(element, errorClass, validClass) {
            $(element).closest('p.form-field').removeClass('field-'+validClass).addClass('field-'+errorClass);
            //checkFormStatus();
          },
          unhighlight: function(element, errorClass, validClass) {
            $(element).closest('p.form-field').removeClass('field-'+errorClass).addClass('field-'+validClass);
            //checkFormStatus();
          }
        });
      }
    }

  };

  TeSEO.socialShare = {

    init : function(baseUrl, counters, tracking){

      var enabledCounters = (counters === 'on');
      var enabledTracking = (tracking === 'on');

      $('.share-googleplus').each(function(){
        $(this).sharrre({
          share: {
            googlePlus: true
          },
          urlCurl: baseUrl+'/lib/sharrre.php',
          enableHover: false,
          enableTracking: enabledTracking,
          enableCounter: enabledCounters,
          template: '<span class="counter">{total}</span>',
          click: function(api, options){
            api.simulateClick();
            api.openPopup('googlePlus');
          }
        });
      });

      $('.share-facebook').each(function(){
        $(this).sharrre({
          share: {
            facebook: true
          },
          enableHover: false,
          enableTracking: enabledTracking,
          enableCounter: enabledCounters,
          template: '<span class="counter">{total}</span>',
          click: function(api, options){
            api.simulateClick();
            api.openPopup('facebook');
          }
        });
      });

      $('.share-twitter').each(function(){
        $(this).sharrre({
          share: {
            twitter: true
          },
          enableHover: false,
          enableTracking: enabledTracking,
          enableCounter: false,
          template: '<span class="counter">{total}</span>',
          click: function(api, options){
            api.simulateClick();
            api.openPopup('twitter');
          }
        });
      });

      $('.share-linkedin').each(function(){
        $(this).sharrre({
          share: {
            linkedin: true
          },
          enableHover: false,
          enableTracking: enabledTracking,
          enableCounter: enabledCounters,
          template: '<span class="counter">{total}</span>',
          click: function(api, options){
            api.simulateClick();
            api.openPopup('linkedin');
          }
        });
      });

    }

  };


  TeSEO.cookieConsent = {

    cookieKey: "CKPLCY",
    scrollLimit: 150,
    expireTime: 31,

    init: function(){
      if($disclaimer.length){
        if(typeof Cookies.get(TeSEO.cookieConsent.cookieKey) === "undefined"){
          $disclaimer.show();
          if($disclaimer.hasClass('cookies-to-top')){
            $body.addClass('top-disclaimer');
          }
        } else {
          $disclaimer.hide();
          $body.removeClass('top-disclaimer');
        }

        $('#cookie-policy-disclaimer .accept').click (function (e) {
          Cookies.set(TeSEO.cookieConsent.cookieKey, 'true', { expires: TeSEO.cookieConsent.expireTime });
          $disclaimer.slideUp();
          $body.removeClass('top-disclaimer');
        });

        // $window.on("scroll", TeSEO.cookieConsent.acceptOnScroll);
      }
    },

    acceptOnScroll: function () {
      if ( $window.scrollTop() > TeSEO.cookieConsent.scrollLimit ) {
        Cookies.set(TeSEO.cookieConsent.cookieKey, 'true', { expires: TeSEO.cookieConsent.expireTime });
        $disclaimer.slideUp();
        $body.removeClass('top-disclaimer');
        $window.off("scroll", TeSEO.cookieConsent.acceptOnScroll);
      }
    }

  };

  TeSEO.isMobile = {
      Android: function() {
        return navigator.userAgent.match(/Android/i);
      },
      BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
      },
      iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
      },
      Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
      },
      Windows: function() {
        return navigator.userAgent.match(/IEMobile/i);
      },
      any: function() {
        return (TeSEO.isMobile.Android() || TeSEO.isMobile.BlackBerry() || TeSEO.isMobile.iOS() || TeSEO.isMobile.Opera() || TeSEO.isMobile.Windows());
      }
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var ROOT = {
    // All pages
    'common': {
      init: function() {
        console.log('TeSEO init');


        $('.alert > .close').on('click', function(e){
          $(this).parent().alert('close');
        });

        TeSEO.main.globalEvents();
        TeSEO.main.responsiveClasses();
        TeSEO.main.resizeVideos();
        TeSEO.main.pageTransition();
        TeSEO.main.sidePanel();
        TeSEO.main.animations();
        TeSEO.main.linkScroll();
        TeSEO.main.goToTop();
        TeSEO.main.uniform();
        TeSEO.slider.init();
        TeSEO.main.imagePreload('.portfolio-item img');
        TeSEO.main.lightbox();
        //TeSEO.main.slimScroll();
        //TeSEO.main.imageFade();
      },

      load: function(){
        console.log('TeSEO load');
        // quitado para prevenir un margen excesivo
        // TeSEO.main.stickFooterOnSmall();
        TeSEO.main.stickyFooter();
        TeSEO.main.parallax();
      },

      resize: function() {
        console.log('TeSEO resize');
        TeSEO.main.stickyFooter();
        TeSEO.main.dataResponsiveClasses();
        TeSEO.main.prepareStickyMenu();
        TeSEO.main.stickyMenu();
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }

    }/*,
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'sobre_nosotros': {
      init: function() {
        // JavaScript to be fired on the about us page
        //console.log('sobre nosotros');
        //TeSEO.timeLine.init();
      }
    }*/
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = ROOT;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common', 'init');
      UTIL.fire('common', 'resize');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  var $window = $(window),
      $body = $('body'),
      $wrapper = $('#wrapper'),
      $header = $('#header'),
      $headerWrap = $('#header-wrap'),
      $disclaimer = $('#cookie-policy-disclaimer'),
      $content = $('#content'),
      $footer = $('#footer'),
      oldHeaderClasses = $header.attr('class'),
      oldHeaderWrapClasses = $headerWrap.attr('class'),
      stickyMenuClasses = $header.attr('data-sticky-class'),
      $pageTitle = $('#page-title'),
      $siStickyEl = $('.si-sticky'),
      $goToTopEl = $('#goToTop'),
      $parallax = $('.parallax');

  // Global Events
  $(document).ready(UTIL.loadEvents);
  $window.load( function() { UTIL.fire('common', 'load'); } );

  /** To prevent This code will prevent unexpected menu close when using some components (like accordion, forms, etc) with yam
    * @victor 20170623
    */
  $(document).on('click', '.yamm .dropdown-menu', function(e) {
     e.stopPropagation();
  });



})(jQuery); // Fully reference jQuery after this point.
